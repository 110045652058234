import _ from "lodash";
import { memo, useEffect, useState } from "react";
import { Button, Modal, Carousel, Image } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ILSentence, ILSentenceProblemItem } from "../../types";
import FormEditor from "./FormEditor";

import ImgNothingToCheck from "../../assets/images/nothing_to_check.svg";

const CustomModal = styled(Modal)`
  .modal-content {
    background-color: transparent;
  }
`;

const Indicator = styled.div`
  width: 46px;
  height: 46px;
  line-height: 46px;

  &.active {
    color: black;
    background-color: #ffc848;
    border-color: #ffc848 !important;
  }
`;

const ILCEditorDialog = memo(
  ({
    show,
    sentences,
    onChange,
    onClose,
  }: {
    show: boolean;
    sentences: ILSentence[];
    onChange: () => void;
    onClose: () => void;
  }) => {
    const [cardIndex, setCardIndex] = useState<number>(0);
    const [problems, setProblems] = useState<ILSentenceProblemItem[]>([]);

    useEffect(() => {
      setProblems(
        sentences.reduce((prev: ILSentenceProblemItem[], curr) => {
          prev.push(..._.map(curr.problems, (s) => ({ sentence: curr, ...s })));

          return prev;
        }, [])
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange]);

    const handleCloseClick = () => {
      setCardIndex(0);

      onClose();
    };

    return (
      <CustomModal
        show={show}
        size="xl"
        centered
        contentClassName="text-light border-0"
      >
        <Modal.Header>
          <Modal.Title className="h5">Suggestion</Modal.Title>
          <div
            onClick={() => handleCloseClick()}
            role="button"
            className="d-flex flex-column"
          >
            <FontAwesomeIcon icon="close" />
            <small>Close</small>
          </div>
        </Modal.Header>
        {!_.isEmpty(problems) && problems[cardIndex].suggestions.length && (
          <div className="h5 text-white py-2 text-center">
            {problems[cardIndex].suggestions.length > 1 ? (
              <>
                {problems[cardIndex].suggestions.length} suggestions for review
              </>
            ) : (
              <>
                {problems[cardIndex].suggestions.length} suggestion for review
              </>
            )}
          </div>
        )}
        {!_.isEmpty(problems) ? (
          <Modal.Body className="p-4 pb-3 rounded-4 bg-black">
            <Carousel
              activeIndex={cardIndex}
              controls={false}
              indicators={false}
              interval={null}
              onSelect={(index) => setCardIndex(index)}
              keyboard={false}
            >
              {problems.map((problem, i) => (
                <Carousel.Item
                  key={`${i}_${problem.sentence.phrases.join("_")}`}
                >
                  <div className="mb-2">
                    We have found some more inclusive options than "
                    {problem.phrase}".
                  </div>
                  <div className="mb-2">Here are some alternatives:</div>
                  <FormEditor item={problem} onChange={onChange} />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
        ) : (
          <Modal.Body className="mt-4 p-4 pb-3 rounded-4 bg-black text-center">
            <div className="p-5">
              <Image src={ImgNothingToCheck} style={{ maxWidth: 200 }} />
              <div className="my-5">
                Great work! We didn't find any non-disability inclusive language
                in your text.
              </div>
              <Button className="px-3" onClick={handleCloseClick}>
                Back to Inclusive Language Tool
                <FontAwesomeIcon icon="arrow-left" className="ms-3" />
              </Button>
            </div>
          </Modal.Body>
        )}
        {problems.length > 1 && (
          <div className="mt-4 d-flex justify-content-center">
            {problems.map((_p, i) => (
              <Indicator
                key={i}
                role="button"
                onClick={() => setCardIndex(i)}
                className={`rounded-circle text-center mx-2 border ${
                  i === cardIndex ? "active" : ""
                }`}
              >
                {i + 1}
              </Indicator>
            ))}
          </div>
        )}
        <div className="mt-4 d-flex justify-content-center">
          {problems.length > 1 && (
            <>
              {cardIndex !== 0 && (
                <Button
                  className="mx-1"
                  onClick={() => setCardIndex(cardIndex - 1)}
                >
                  <FontAwesomeIcon icon="arrow-left" className="me-3" />
                  Previous
                </Button>
              )}
              {cardIndex !== problems.length - 1 && (
                <Button
                  className="mx-1"
                  onClick={() => setCardIndex(cardIndex + 1)}
                >
                  Next
                  <FontAwesomeIcon icon="arrow-right" className="ms-3" />
                </Button>
              )}
            </>
          )}
          {problems.length > 0 && (
            <Button
              variant="success"
              className="mx-1"
              onClick={() => handleCloseClick()}
            >
              Finish
              <FontAwesomeIcon icon="check" className="ms-3" />
            </Button>
          )}
        </div>
      </CustomModal>
    );
  }
);

export default ILCEditorDialog;
