import { useMemo } from "react";
import { Toaster } from "react-hot-toast";

import { Footer, Header, Toolbar } from "./components";
import { InclusiveLanguagePage } from "./pages";
import useDarkMode from "./utils/darkMode";

function App() {
  const { isDarkMode } = useDarkMode();

  useMemo(() => {
    document
      .getElementsByTagName("HTML")[0]
      .setAttribute("data-theme", isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Toolbar />
      <Header />
      <InclusiveLanguagePage />
      <Footer />
    </>
  );
}

export default App;
