import _ from "lodash";
import { DateTime } from "luxon";
import { useState } from "react";
import { toast } from "react-hot-toast";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Form, Button, Row, Col, Image } from "react-bootstrap";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";

import { ILCEditorDialog } from "../components";
import { ILParagraph } from "../types";
import { useApi } from "../utils/axios";
import { schar } from "../utils/schar";
import useDarkMode from "../utils/darkMode";

import ImgChatBanner from "../assets/images/chat_banner.png";
import ImgHomeManProfile from "../assets/images/home_man_profile.jpg";

const BannerWrapper = styled.div`
  background-color: #ffc848;
`;

const ILTWrapper = styled.div`
  background-color: var(--bg-color);
  color: var(--text-color);
`;

const TextField = styled.div<{ $isDarkMode?: boolean }>`
  min-height: 150px;
  max-height: 300px;
  overflow-y: auto;
  border: 2px solid transparent;
  border-color: ${({ $isDarkMode }) =>
    $isDarkMode === true ? "#737373" : "transparent"};
  background-color: var(--bg-textfield-color);

  mark {
    border: 0;
    position: relative;
    padding: 0;
    font-weight: 600;
    letter-spacing: 1.4px;
    background-color: #ffe9b6;
  }
`;

const CheckButton = styled(Button)`
  --bs-btn-bg: #d7b2fc !important;
  --bs-btn-border-color: #d7b2fc !important;
  --bs-btn-hover-bg: #d7b2fc !important;
  --bs-btn-hover-border-color: #d7b2fc !important;
  --bs-btn-active-bg: #d7b2fc !important;
  --bs-btn-active-border-color: #d7b2fc !important;
  --bs-btn-disabled-bg: #d7b2fc !important;
  --bs-btn-disabled-border-color: #d7b2fc !important;
`;

const OriginalButton = styled(Button)`
  --bs-btn-bg: #ffe9b6 !important;
  --bs-btn-border-color: #ffe9b6 !important;
  --bs-btn-hover-bg: #ffe9b6 !important;
  --bs-btn-hover-border-color: #ffe9b6 !important;
  --bs-btn-active-bg: #ffe9b6 !important;
  --bs-btn-active-border-color: #ffe9b6 !important;
`;

const OutlineButton = styled(Button)`
  --bs-btn-color: var(--text-outline-color) !important;
  --bs-btn-hover-color: var(--text-outline-hover-color) !important;
  --bs-btn-disabled-color: var(--text-outline-color) !important;
  --bs-btn-border-color: var(--bg-outline-color) !important;
  --bs-btn-hover-bg: var(--bg-outline-color) !important;
  --bs-btn-hover-border-color: var(--bg-outline-color) !important;
  --bs-btn-active-bg: var(--bg-outline-color) !important;
  --bs-btn-active-border-color: var(--bg-outline-color) !important;
`;

const InclusiveLanguagePage = () => {
  const { isDarkMode } = useDarkMode();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [loadingGetILC, getILC] = useApi<
    { statusCode: number; body: string },
    { paragraph: string }
  >({
    method: "POST",
    url: "/inclusivelanguagechecker",
  });

  const [loadingPostICL, postILC] = useApi<
    { statusCode: number },
    {
      date: string;
      sentences: string[];
      sanitised_sentences: string[];
    }
  >({
    method: "POST",
    url: "/data",
  });

  const [paragraph, setParagraph] = useState<ILParagraph>({
    content:
      process.env.NODE_ENV !== "production"
        ? `Large button phones allow a larger surface for the visually impaired person to feel the correct buttons when dialing and also have larger text. Teachers of the deaf and hard-of-hearing may teach a wide range of subjects in a support unit for the hearing impaired, or help support students in classes in mainstream schools. They may use a variety of signed languages to teach and communicate in the classroom.`
        : "",
    sentences: [],
  });

  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);

  const doILCCheck = async (): Promise<any> => {
    setIsLoading(true);
    setParagraph((prev) => ({ ...prev, sentences: [] }));

    try {
      const { data } = await getILC({
        data: { paragraph: paragraph.content.replaceAll(schar, "") },
      });

      if (data.statusCode !== 200) {
        throw new Error("Server Error.");
      }

      const json = JSON.parse(data.body);

      setParagraph((prev) => {
        const newParagraph: ILParagraph = {
          ...prev,
          rawContent: json.paragraph_content,
          content: json.paragraph_content,
          sentences: _.map(json.sentences, (s) => {
            let raw: string = s.sentence_text;

            _.forEach(s.problem_content, (p, i: number) => {
              raw = _.replace(raw, p.problem_phrase, `${schar}{${i}}`);
            });

            return {
              num: s.sentence_number,
              phrases: _.map(
                s.problem_content,
                (p) => `${schar}${_.trim(p.problem_phrase)}`
              ),
              rawText: s.sentence_text,
              text: raw,
              problems: _.map(s.problem_content, (p, i: number) => ({
                index: i,
                startIndex: p.start_index,
                endIndex: p.end_index,
                fact: p.educational_fact,
                phrase: `${schar}${_.trim(p.problem_phrase)}`,
                text: p.problem_text,
                suggestions: _.map(p.suggestions, (su) => _.trim(su)),
              })),
            };
          }),
        };

        newParagraph.content = _(newParagraph.sentences)
          .map((s) => {
            let text = _.clone(s.text);

            _.forEach(s.phrases, (p, i) => {
              text = _.replace(text, `${schar}{${i}}`, p);
            });

            return text;
          })
          .join(" ");
        newParagraph.rawContent = _.clone(newParagraph.content);

        return newParagraph;
      });

      setIsShowDialog(true);
    } catch (error) {
      toast.error("Server error.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (_.isEmpty(paragraph.content)) {
      toast.error("Please enter your text.");

      return;
    }

    doILCCheck();
  };

  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(paragraph.content);

    toast.success("Text copied to clipboard!");

    await postILC({
      data: {
        date: DateTime.now().toISO(),
        sentences: _(paragraph.sentences)
          .map((s) => s.rawText)
          .value(),
        sanitised_sentences: _(paragraph.sentences)
          .map((s) => {
            let text = _.clone(s.text);

            _.forEach(s.phrases, (p, i) => {
              text = _.replace(text, `${schar}{${i}}`, p);
            });

            return text;
          })
          .value(),
      },
    });
  };

  const handleClearClick = () => {
    setParagraph((prev) => ({
      ...prev,
      content: "",
      rawContent: null,
      sentences: [],
    }));
  };

  const handleUndoClick = () => {
    setParagraph((prev) => ({
      ...prev,
      content: paragraph.rawContent || "",
    }));
  };

  const handleILCTextChange = () => {
    paragraph.content = _(paragraph.sentences)
      .map((s) => {
        let text = _.clone(s.text);

        _.forEach(s.phrases, (p, i) => {
          text = _.replace(text, `${schar}{${i}}`, p);
        });

        return text;
      })
      .join(" ");
  };

  return (
    <div className="overflow-hidden">
      <BannerWrapper className="py-5">
        <Container>
          <div className="d-flex align-items-center">
            <span>Home</span>
            <FontAwesomeIcon icon="chevron-right" className="mx-2" size="xs" />
            <span>Inclusive language tool</span>
          </div>
          <div className="my-5 d-flex align-items-center">
            <Image src={ImgChatBanner} height={90} />
            <div className="ms-3 display-1 fw-bold">
              Inclusive language tool
            </div>
          </div>
          <div className="mb-0 h4">
            Check to ensure you’re using the most disability inclusive language
            throughout the recruitment process and beyond.
          </div>
        </Container>
      </BannerWrapper>
      <ILTWrapper className="py-5">
        <Container>
          <Row className="justify-content-center g-5">
            <Col lg={8}>
              <div className="mb-4 h1 fw-bold">Inclusive language tool</div>
              <div className="mb-5 h5">
                Type or paste text below to check the inclusivity of your
                language. Where relevant, our tool will make suggestions for
                more disability inclusive alternatives.
              </div>
              <Form onSubmit={handleFormSubmit}>
                <Form.Group>
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Label className="m-0 h5 fw-bold">
                      Text Field
                    </Form.Label>
                    {paragraph.sentences.length > 0 &&
                      !_.isNil(paragraph.rawContent) && (
                        <Button>
                          <FontAwesomeIcon icon="check" className="me-2" />
                          Reviewed text
                        </Button>
                      )}
                  </div>
                  <TextField
                    className="mt-3 position-relative p-4 shadow rounded-4"
                    $isDarkMode={isDarkMode}
                  >
                    <HighlightWithinTextarea
                      placeholder="Type or paste your text here."
                      value={paragraph.content}
                      highlight={[
                        {
                          highlight: paragraph.sentences.map((s) =>
                            s.problems.map((p) => `${p.phrase}`)
                          ),
                        },
                        {
                          highlight: paragraph.sentences.map((s) =>
                            s.phrases.map((p) => `${p}`)
                          ),
                          component: (props) => {
                            return (
                              <mark style={{ backgroundColor: "#efe0fe" }}>
                                {props.children}
                              </mark>
                            );
                          },
                        },
                      ]}
                      onChange={(e: string) =>
                        setParagraph((prev) => ({ ...prev, content: e }))
                      }
                      readOnly={isLoading || loadingGetILC || loadingPostICL}
                    />
                  </TextField>
                </Form.Group>
                <div className="mt-5 d-flex">
                  <div className="d-flex">
                    <CheckButton
                      type="submit"
                      disabled={isLoading || loadingGetILC || loadingPostICL}
                      className="me-3 fw-bold"
                    >
                      Check language
                      <FontAwesomeIcon icon="check" className="ms-3" />
                    </CheckButton>
                    {!_.isEmpty(paragraph.rawContent) && (
                      <OutlineButton
                        type="button"
                        variant="outline-dark"
                        onClick={handleCopyClick}
                        disabled={_.isEmpty(paragraph.content)}
                        className="me-3 fw-bold"
                      >
                        Copy updated text
                        <FontAwesomeIcon icon="clipboard" className="ms-3" />
                      </OutlineButton>
                    )}
                    {!_.isEmpty(paragraph.rawContent) && (
                      <OutlineButton
                        type="button"
                        variant="outline-dark"
                        onClick={handleClearClick}
                        disabled={_.isEmpty(paragraph.content)}
                        className="me-3 fw-bold"
                      >
                        Clear
                        <FontAwesomeIcon icon="refresh" className="ms-3" />
                      </OutlineButton>
                    )}
                  </div>
                  {!_.isEmpty(paragraph.rawContent) && (
                    <OutlineButton
                      type="button"
                      variant="outline-dark"
                      onClick={handleUndoClick}
                      disabled={_.isEqual(
                        paragraph.rawContent,
                        paragraph.content
                      )}
                      className="ms-auto fw-bold"
                    >
                      Undo
                      <FontAwesomeIcon icon="pen" className="ms-3" />
                    </OutlineButton>
                  )}
                </div>
              </Form>
              {paragraph.sentences.length > 0 &&
                !_.isNil(paragraph.rawContent) && (
                  <>
                    <hr className="my-5" />
                    <Form.Group>
                      <div className="d-flex align-items-center justify-content-between">
                        <Form.Label className="m-0 h5 fw-bold">
                          Text Field
                        </Form.Label>
                        <OriginalButton variant="warning">
                          Original Text
                        </OriginalButton>
                      </div>
                      <TextField
                        className="mt-3 position-relative p-4 shadow rounded-4"
                        $isDarkMode={isDarkMode}
                      >
                        <HighlightWithinTextarea
                          placeholder="Type or paste your text here."
                          value={paragraph.rawContent}
                          highlight={paragraph.sentences.map((s) =>
                            s.problems.map((p) =>
                              p.phrase.replaceAll(`${schar}`, "")
                            )
                          )}
                          readOnly={true}
                        />
                      </TextField>
                    </Form.Group>
                  </>
                )}
              {!_.isEmpty(paragraph.sentences) && (
                <ILCEditorDialog
                  show={isShowDialog}
                  sentences={paragraph.sentences}
                  onChange={handleILCTextChange}
                  onClose={() => {
                    setIsShowDialog(false);
                  }}
                />
              )}
            </Col>
            <Col lg={4}>
              <div className="h2 fw-bold">Play the Field</div>
              <div className="my-5 text-center">
                <Button
                  as="a"
                  href="https://www.thefield.jobs/Job/Search"
                  variant="warning"
                  className="px-5 fw-bold"
                >
                  Search the Field
                </Button>
              </div>
              <Image src={ImgHomeManProfile} className="w-100 rounded-4" />
            </Col>
          </Row>
        </Container>
      </ILTWrapper>
    </div>
  );
};

export default InclusiveLanguagePage;
