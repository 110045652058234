import _ from "lodash";
import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import { Button, Form, Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";

import { ILSentenceProblemItem } from "../../types";
import { schar } from "../../utils/schar";

const Editor = styled.div`
  min-height: 120px;
  border-width: 2px !important;
  border-color: #999 !important;

  mark {
    border: 0;
    position: relative;
    padding: 0;
    color: white;
    font-weight: 600;
    letter-spacing: 1.4px;
    background-color: transparent;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      border-bottom: 1px dashed #efe0fe;
    }
  }

  &.is-edited {
    mark {
      color: #efe0fe;

      &::before {
        border-bottom-color: transparent;
      }
    }
  }
`;

const FormEditor = ({
  item,
  onChange,
}: {
  item: ILSentenceProblemItem;
  onChange: () => void;
}) => {
  const [isOpenFact, setIsOpenFact] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);

  const [currentText, setCurrentText] = useState<string>("");

  useEffect(() => {
    setIsEdited(!_.isEqual(item.phrase, item.sentence.phrases[item.index]));

    (() => {
      let val = _.clone(item.sentence.text);

      _.forEach(item.sentence.phrases, (p, i) => {
        val = _.replace(val, `${schar}{${i}}`, `${schar}${p}`);
      });

      setCurrentText(val);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.sentence.text, item.sentence.phrases]);

  useMemo(() => {
    onChange();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentText]);

  const getCurrentText = (): { val: string; text: string } => {
    let val = _.clone(item.sentence.text);
    let text = _.clone(val);

    _.forEach(item.sentence.phrases, (p, i) => {
      val = _.replace(val, `${schar}{${i}}`, `${schar}${p}`);
      text = _.replace(text, `${schar}${p}`, `${schar}{${i}}`);
    });

    return { val, text };
  };

  const handleTextChange = (val: string) => {
    let text = _.clone(val);

    _.forEach(item.sentence.phrases, (p, i) => {
      text = _.replace(text, `${schar}${p}`, `${schar}{${i}}`);
    });

    item.sentence.text = text;

    setCurrentText(val);
  };

  const handlePhraseClick = (suggestion: string) => {
    item.sentence.phrases[item.index] = `${schar}${suggestion}`;

    const { val, text } = getCurrentText();

    item.sentence.text = text;

    setCurrentText(val);
    setIsEdited(true);
  };

  const handleUndoClick = () => {
    item.sentence.phrases[item.index] = item.phrase;

    const { val, text } = getCurrentText();

    item.sentence.text = text;

    setCurrentText(val);
    setIsEdited(false);
  };

  return (
    <>
      <div className="mb-3">
        {item.suggestions.map((suggestion, x) => (
          <Button
            key={x}
            className="me-2"
            onClick={() => handlePhraseClick(suggestion)}
            disabled={item.sentence.phrases[item.index] === suggestion}
          >
            {suggestion}
          </Button>
        ))}
        {isEdited && (
          <Button variant="warning" className="px-3" onClick={handleUndoClick}>
            Undo
            <FontAwesomeIcon icon="pen" className="ms-3" />
          </Button>
        )}
      </div>
      <Form.Group className="mb-4">
        <Form.Label>Update your text below:</Form.Label>
        <Editor
          className={`p-3 bg-dark border text-white rounded-0 ${
            isEdited ? "is-edited" : ""
          }`}
        >
          <HighlightWithinTextarea
            placeholder="Type or paste your text here."
            value={currentText}
            highlight={[
              `${schar}${
                isEdited ? item.sentence.phrases[item.index] : item.phrase
              }`,
            ]}
            onChange={handleTextChange}
          />
        </Editor>
      </Form.Group>
      <div
        className="px-3 py-2 mb-2 bg-dark"
        role="button"
        onClick={() => setIsOpenFact(!isOpenFact)}
      >
        Why have we suggested more inclusive alternatives to "{item.phrase}"?
      </div>
      <Collapse in={isOpenFact}>
        <div>{item.fact}</div>
      </Collapse>
    </>
  );
};

export default FormEditor;
