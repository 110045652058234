import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Image, Row } from "react-bootstrap";

import Collapse from "./Collapse";
import { useBreakpoint } from "../../utils/bs";

import ImgLogoBlack from "../../assets/images/logo-black.svg";
import ImgFlag1 from "../../assets/images/flag1.png";
import ImgFlag2 from "../../assets/images/flag2.png";
import ImgFlagLGBT from "../../assets/images/flag_lgbt.png";

const Wrapper = styled.div`
  background-color: #d7b2fc;

  a {
    color: black;
  }

  hr {
    border: none;
    background-color: black;
    height: 0.5px;
    opacity: 1;
  }
`;

const Footer = () => {
  const size = useBreakpoint();

  return (
    <Wrapper className="py-5">
      <Container>
        <div className="py-3">
          <Row>
            <Col lg={3}>
              <Collapse title="About us" className="py-3 py-lg-0" size={size}>
                <ul className="m-0 p-0 list-group-numbered">
                  <li>
                    <a href="https://www.thefield.jobs/TheField/AboutUs#theField">
                      Who is the Field?
                    </a>
                  </li>
                  <li className="mt-2">
                    <a href="https://www.thefield.jobs/TheField/AboutUs#Founders">
                      Meet our founders
                    </a>
                  </li>
                  <li className="mt-2">
                    <a href="https://www.thefield.jobs/TheField/AboutUs#Team">
                      Our team
                    </a>
                  </li>
                  <li className="mt-2">
                    <a href="https://www.thefield.jobs/TheField/AboutUs#Beliefs">
                      Our beliefs and values
                    </a>
                  </li>
                  <li className="mt-2">
                    <a href="https://www.thefield.jobs/Employer/PublicProfile?SiteId=0&Id=752">
                      Work with us
                    </a>
                  </li>
                </ul>
              </Collapse>
            </Col>
            <Col lg={3}>
              <Collapse title="Resources" className="py-3 py-lg-0" size={size}>
                <ul className="m-0 p-0 list-group-numbered">
                  <li>
                    <a href="https://help.thefield.jobs/hc/en-us/categories/9500776451737-FAQs">
                      FAQs
                    </a>
                  </li>
                  <li className="mt-2">
                    <a href="https://www.thefield.jobs/TheField/Subscriptions">
                      Subscription Packages
                    </a>
                  </li>
                  <li className="mt-2">
                    <a href="https://getskilledaccess.com.au/">
                      Get Skilled Access
                    </a>
                  </li>
                </ul>
              </Collapse>
            </Col>
            <Col lg={3}>
              <Collapse title="Support" className="py-3 py-lg-0" size={size}>
                <ul className="m-0 p-0 list-group-numbered">
                  <li>
                    <a href="https://help.thefield.jobs/hc/en-us">
                      Help Centre
                    </a>
                  </li>
                  <li className="mt-2">
                    <a href="https://www.thefield.jobs/Job/ContactUs">
                      Contact us
                    </a>
                  </li>
                  <li className="mt-2">
                    <a href="https://www.thefield.jobs/Job/ContactUs">Chat</a>
                  </li>
                </ul>
              </Collapse>
            </Col>
            <Col lg={3}>
              <Collapse title="Contact" className="py-3 py-lg-0" size={size}>
                <ul className="m-0 p-0 list-group-numbered">
                  <li>
                    <b>Phone</b>: <a href="tel:0398300190">03 9830 0190</a>
                  </li>
                  <li className="mt-2">
                    <b>Email</b>:{" "}
                    <a href="mailto:support@thefield.jobs">
                      support@thefield.jobs
                    </a>
                  </li>
                  <li className="mt-2">
                    <b>Business house</b>: Mon-Fri 9am-5pm AEST
                  </li>
                </ul>
              </Collapse>
            </Col>
          </Row>
        </div>
        <hr className="d-none d-lg-block" />
        <div className="pb-3 pt-lg-3">
          <Row>
            <Col lg={6}>
              <Collapse
                title="Accessibility statement"
                className="py-3 py-lg-0"
                size={size}
              >
                <div className="mb-2">
                  The Field is committed to ensuring digital accessibility for
                  people with disabilities. We are continually improving the
                  user experience for everyone and applying the relevant
                  accessibility standards.
                </div>
                <div>
                  Our site is designed using the Web Content Accessibility
                  Guidelines (WCAG). We welcome your feedback on the
                  accessibility of our site. Please let us know if you encounter
                  any problems. Phone: <a href="tel:0398300190">03 9830 0190</a>
                  , E-mail:{" "}
                  <a href="mailto:support@thefield.jobs">
                    support@thefield.jobs
                  </a>
                </div>
              </Collapse>
            </Col>
            <Col lg={6}>
              <Collapse
                title="Acknowledgements"
                className="py-3 py-lg-0"
                size={size}
              >
                <div className="mb-2">
                  The Field acknowledges the Traditional Owners and Custodians
                  of the lands on which we work and pays our respect to Elders
                  past and present.
                </div>
                <div className="my-3">
                  <Image src={ImgFlag1} alt="" width={50} />
                  <span className="px-2" />
                  <Image src={ImgFlag2} alt="" width={50} />
                </div>
                <div className="mb-3">
                  We welcome all cultures, all religions, all colours, all
                  beliefs, all ages, all sizes, all types, all people.
                </div>
                <Image src={ImgFlagLGBT} alt="" width={50} />
              </Collapse>
            </Col>
          </Row>
        </div>
        <hr className="d-none d-lg-block" />
        <div className="d-flex align-items-center justify-content-center justify-content-lg-start py-3">
          <div className="d-none d-lg-block h5 fw-bold mb-0 me-4">
            Find us on social media
          </div>
          <a
            href="https://www.linkedin.com/company/the-field-jobs"
            className="me-3"
          >
            <FontAwesomeIcon icon={["fab", "linkedin"]} size="3x" />
          </a>
          <a href="https://www.facebook.com/theField.jobs" className="me-3">
            <FontAwesomeIcon icon={["fab", "facebook"]} size="3x" />
          </a>
          <a href="https://www.instagram.com/thefield.jobs" className="me-3">
            <FontAwesomeIcon icon={["fab", "instagram"]} size="3x" />
          </a>
          <a href="https://twitter.com/theFieldjobs">
            <FontAwesomeIcon icon={["fab", "twitter"]} size="3x" />
          </a>
        </div>
        <hr />
        <div className="d-flex flex-column flex-lg-row pt-4">
          <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-start">
            <a href="https://www.thefield.jobs/" className="me-4">
              <Image src={ImgLogoBlack} alt="" width={200} />
            </a>
            <div className="d-flex flex-column flex-lg-row">
              <div className="mt-2 mt-lg-0 mb-2 mb-lg-0 text-center text-lg-start">
                © 2022 the Field Jobs
                <br />
                Trading Pty Ltd
              </div>
              <div className="ms-0 ms-lg-5">ABN 58 655 094 465</div>
            </div>
          </div>
          <div className="ms-0 ms-lg-auto mt-4 mt-lg-0 d-flex flex-column flex-lg-row text-center text-lg-end align-items-center">
            <a
              href="https://www.thefield.jobs/TheField/PrivacyPolicy"
              className="ms-0 ms-lg-3"
            >
              Privacy statement
            </a>
            <a
              href="https://www.thefield.jobs/Register/TermsAndConditions"
              className="ms-0 ms-lg-3"
            >
              Terms and conditions
            </a>
            <a
              href="https://help.thefield.jobs/hc/en-us/articles/9531057184665-Using-the-Field-responsibly"
              className="ms-0 ms-lg-3"
            >
              Using the Field responsibly
            </a>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default Footer;
