import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faClose,
  faCheck,
  faArrowLeft,
  faArrowRight,
  faPen,
  faClipboard,
  faRefresh,
  faAngleRight,
  faChevronRight,
  faCopyright,
} from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faClose,
  faCheck,
  faArrowLeft,
  faArrowRight,
  faPen,
  faClipboard,
  faRefresh,
  faAngleRight,
  faChevronRight,
  faCopyright,
  faLinkedin,
  faFacebook,
  faInstagram,
  faTwitter
);
