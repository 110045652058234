import {
  createGlobalStyle,
  css,
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from "styled-components/macro";

import SharpSansBookWoff from "../assets/fonts/SharpSans-Book.woff";
import SharpSansNo1BoldWoff from "../assets/fonts/SharpSansNo1-Bold.woff";
import SharpSansNo1MediumWoff from "../assets/fonts/SharpSansNo1-Medium.woff";

import "./fortawesome";
import { Colors } from "./styled";

const colors = (): Colors => ({});

const getTheme = (): DefaultTheme => ({
  ...colors(),
});

export const ThemedGlobalStyle = createGlobalStyle`${css`
  @font-face {
    font-family: "Sharp Sans Book";
    src: url(${SharpSansBookWoff}) format("truetype");
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Sharp Sans";
    src: url(${SharpSansNo1MediumWoff}) format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Sharp Sans";
    src: url(${SharpSansNo1BoldWoff}) format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  html[data-theme="light"] {
    --bg-color: white;
    --bg-textfield-color: white;
    --bg-outline-color: #1e1d1d;
    --text-color: black;
    --text-outline-color: #1e1d1d;
    --text-outline-hover-color: white;
  }

  html[data-theme="dark"] {
    --bg-color: black;
    --bg-textfield-color: #1e1d1d;
    --bg-outline-color: white;
    --text-color: white;
    --text-outline-color: white;
    --text-outline-hover-color: #1e1d1d;
  }

  body {
    font-family: "Sharp Sans", sans-serif;
    background-color: #e5e5e5;
  }

  .bg-dark {
    background-color: #1e1d1d !important;
  }

  .modal-backdrop {
    --bs-backdrop-opacity: 0.9 !important;

    &.show {
      background-color: #222222;
    }
  }

  .btn {
    --bs-btn-color: black;
    --bs-btn-hover-color: black;
    --bs-btn-active-color: black;
    --bs-btn-disabled-color: #111111;
    border-radius: 0px;
  }

  .btn-primary {
    --bs-btn-bg: #efe0fe;
    --bs-btn-border-color: #efe0fe;
    --bs-btn-hover-bg: #efe0fe;
    --bs-btn-hover-border-color: #efe0fe;
    --bs-btn-active-bg: #efe0fe;
    --bs-btn-active-border-color: #efe0fe;
    --bs-btn-disabled-bg: #efe0fe;
    --bs-btn-disabled-border-color: #efe0fe;
  }

  .btn-success {
    --bs-btn-bg: #ffc848;
    --bs-btn-border-color: #ffc848;
    --bs-btn-hover-bg: #ffc848;
    --bs-btn-hover-border-color: #ffc848;
    --bs-btn-active-bg: #ffc848;
    --bs-btn-active-border-color: #ffc848;
  }

  .btn-warning {
    --bs-btn-bg: #ffc107;
    --bs-btn-border-color: #ffc107;
    --bs-btn-hover-bg: #ffc107;
    --bs-btn-hover-border-color: #ffc107;
    --bs-btn-active-bg: #ffc107;
    --bs-btn-active-border-color: #ffc107;
  }

  .btn-outline-dark {
    &:hover {
      --bs-btn-bg: transparent;
      --bs-btn-hover-bg: transparent;
      --bs-btn-active-bg: transparent;
    }
  }
`}`;

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledComponentsThemeProvider theme={getTheme()}>
      {children}
    </StyledComponentsThemeProvider>
  );
};

export default ThemeProvider;
