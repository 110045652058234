import { useState } from "react";
import Promise from "bluebird";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const useApi = <T, D>(
  baseConfig?: AxiosRequestConfig<D>
): [
  boolean,
  (config?: AxiosRequestConfig<D>) => Promise<AxiosResponse<T, D>>
] => {
  const [loading, setLoading] = useState<boolean>(false);

  const request = async (
    config?: AxiosRequestConfig<D>
  ): Promise<AxiosResponse<T, D>> => {
    try {
      setLoading(true);

      await Promise.delay(200);

      return instance.request({ ...baseConfig, ...config });
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return [loading, request];
};
