import { ReactNode, useState } from "react";
import { Collapse as BsCollapse } from "react-bootstrap";
import styled from "styled-components";

import { Breakpoint } from "../../utils/bs";

const Wrapper = styled.div`
  border-color: black !important;
`;

const Collapse = ({
  title,
  children,
  className,
  size,
}: {
  title: string;
  children: ReactNode;
  className?: string;
  size?: Breakpoint;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(size !== "xs");

  return (
    <Wrapper className={`${className} ${size === "xs" ? "border-bottom" : ""}`}>
      <div
        role="button"
        className="h5 mb-4 fw-bold"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
      </div>
      <BsCollapse in={isOpen || size !== "xs"}>
        <div>{children}</div>
      </BsCollapse>
    </Wrapper>
  );
};

export default Collapse;
