import styled from "styled-components";
import { Container, Image } from "react-bootstrap";

import ImgIcChatCircle from "../../assets/images/chat_circle.png";
import ImgIcBook from "../../assets/images/book.png";
import ImgIcDarkMode from "../../assets/images/dark_mode.png";
import useDarkMode from "../../utils/darkMode";

const Wrapper = styled.div`
  background-color: #f7f0fe;
`;

const ItemIcon = styled(Image)`
  height: 22px;
`;

const Toolbar = () => {
  const { isDarkMode, toggle } = useDarkMode();

  return (
    <Wrapper>
      <Container>
        <div className="d-flex justify-content-start justify-content-lg-end">
          <a
            href="https://inclusiveness.thefield.jobs/"
            className="p-2 text-dark"
          >
            <ItemIcon src={ImgIcChatCircle} alt="" className="me-2" />
            Inclusive language tool
          </a>
          <a
            href="https://help.thefield.jobs/hc/en-us/categories/9500715264409-Easy-Read"
            className="p-2 text-dark"
          >
            <ItemIcon src={ImgIcBook} alt="" className="me-2" />
            Easy Read
          </a>
          <span
            className="ms-auto ms-lg-0 btn-link p-2 text-dark"
            role="button"
            onClick={toggle}
          >
            <ItemIcon src={ImgIcDarkMode} alt="" className="me-2" />
            <span className="d-none d-sm-inline">
              {isDarkMode ? "Light mode" : "Dark mode"}
            </span>
          </span>
        </div>
      </Container>
    </Wrapper>
  );
};

export default Toolbar;
