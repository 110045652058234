import { Container, Image, Nav, Navbar, NavDropdown } from "react-bootstrap";
import styled from "styled-components";

import useDarkMode from "../../utils/darkMode";

import ImgLogoWhite from "../../assets/images/logo-white.svg";
import ImgLogoBlack from "../../assets/images/logo-black.svg";
import ImgIcArrowDownBlack from "../../assets/images/arrowDownBlack.png";
import ImgIcArrowDownWhite from "../../assets/images/arrowDownWhite.png";
import ImgIcLogoOnlyDark from "../../assets/images/logo_only.png";
import ImgIcLogoOnlyWhite from "../../assets/images/logoOnlyWhite.png";

const CustomNavbar = styled(Navbar)`
  background-color: var(--bg-color);
  color: var(--text-color);

  a:not(.dropdown-item) {
    color: var(--text-color) !important;
  }
`;

const CustomNav = styled(Nav)`
  font-size: 16px;
`;

const CustomNavLink = styled(Nav.Link)`
  position: relative;

  &.active {
    font-weight: bold;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background-color: var(--text-color);
    }
  }
`;

const CustomNavDropdown = styled(NavDropdown)<{ isDarkMode: boolean }>`
  .dropdown-toggle {
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }

  .dropdown-toggle::after {
    content: "";
    border: none;
    width: 10px;
    height: 6px;
    position: absolute;
    top: 18px;
    background-image: ${({ $isDarkMode }) =>
      $isDarkMode
        ? `url(${ImgIcArrowDownWhite})`
        : `url(${ImgIcArrowDownBlack})`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .dropdown-menu {
    border-radius: 0;
  }
`;

const NavbarToggle = styled(Navbar.Toggle)`
  color: var(--text-color);
  border-color: var(--text-color);
`;

const Header = () => {
  const { isDarkMode } = useDarkMode();

  return (
    <CustomNavbar expand="lg" className="pt-3 pb-4">
      <Container>
        <Navbar.Brand href="https://www.thefield.jobs">
          <Image
            src={isDarkMode ? ImgLogoWhite : ImgLogoBlack}
            alt=""
            width={247}
          />
        </Navbar.Brand>
        <NavbarToggle className="px-4 py-2 rounded-0">
          <Image
            src={isDarkMode ? ImgIcLogoOnlyWhite : ImgIcLogoOnlyDark}
            alt=""
            height={20}
            className="me-2"
          />
          <span className="h6 fw-bold">Menu</span>
        </NavbarToggle>
        <Navbar.Collapse>
          <CustomNav className="pt-lg-2 ps-lg-3 me-auto">
            <CustomNavLink
              href="https://www.thefield.jobs/Job"
              className="me-3 active"
            >
              Home
            </CustomNavLink>
            <CustomNavLink
              href="https://www.thefield.jobs/TheField/AboutUs"
              className="me-3"
            >
              About Us
            </CustomNavLink>
            <CustomNavLink
              href="https://www.thefield.jobs/Job/Search"
              className="me-3"
            >
              Search Jobs
            </CustomNavLink>
            <CustomNavLink
              href="https://www.thefield.jobs/Job/Home#Register"
              className="me-3"
            >
              Play the Field
            </CustomNavLink>
            <CustomNavDropdown title="Support" $isDarkMode={isDarkMode}>
              <NavDropdown.Item href="https://help.thefield.jobs/hc/en-us">
                Help centre
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.thefield.jobs/Job/ContactUs">
                Contact Us
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.thefield.jobs/Job/ContactUs">
                Chat
              </NavDropdown.Item>
            </CustomNavDropdown>
          </CustomNav>
        </Navbar.Collapse>
      </Container>
    </CustomNavbar>
  );
};

export default Header;
